<template>
  <!--  <footer style="
     background-image: url('https://img.freepik.com/free-vector/industrial-textured-background_91008-372.jpg?w=900&t=st=1656044581~exp=1656045181~hmac=4099e2a31cc9c07bed9241545e1659a372fa4a28e4d8cf5bec186162674f16ab'); 
    " class="pt-5"> -->
    <footer>
        <div class="container mt-5">

            <!-- Dekstop -->
        <div class="d-none d-md-block">
            <div class="row mb-5">
                <div class="card text-white bg-success h-90 flex-row">
                    <div class="flex-col md-3 mr-5 ml-3">
                        <h7>Layanan Informasi</h7><br>
                        <h5> <strong>Rumah Sakit Mentari</strong> </h5>
                    </div>
                    <div class="flex-col md-3 mr-5">
                        <h7><strong>Customer Service</strong> </h7><br>
                        <a href="https://wa.me/6281280098088"
                        class="btn btn-pill btn-success float-right"
                        > <i class="fa fa-phone"></i> +62 812-8009-8088</a>
                    </div>
                    <div class="flex-col md-3 mr-5">
                        <h7><strong>Emergency</strong> </h7><br>
                        <a href="https://wa.me/6282129831140"
                        class="btn btn-pill btn-success float-right"
                        > <i class="fa fa-phone"></i> +62 821-2983-1140</a>
                    </div>
                    <div class="flex-col md-3 mr-5">
                        <h7><strong>Rawat Jalan</strong> </h7><br>
                        <a href="https://wa.me/6282249491929"
                        class="btn btn-pill btn-success float-right"
                        > <i class="fa fa-phone"></i> +62 822-4949-1929</a>
                    </div>                   
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 mb-4">
                    <a class="navbar-brand" href="#">
                    <img src="@/assets/logo-rsm.png" alt="" width="150" height="50" />
                    </a>
                    <p>
                        Mendengar, Melayani, Mengasihi.
                    </p>
                    <div class="social">
                        <a href="#" class="mr-4"> <i class="fab fa-facebook fa-2x"></i></a>
                        <a href="#" class="mr-4" style="color:#00c4ff"> <i class="fab fa-twitter fa-2x"></i></a>
                        <a href="#" class="mr-4" style="color: #de2fb8;"> <i class="fab fa-instagram fa-2x"></i></a>
                        <a href="#" style="color: red;"> <i class="fab fa-youtube fa-2x"></i></a>
                    </div>
                </div>
                <div class="col-md-3 mb-4">
                    <div class="row">
                        <h5 class="font-weight-bold ">Informasi</h5>
                        <router-link :to="{ name: 'doctor' }" class="nav-link text-dark">
                            Dokter</router-link>
                        <router-link :to="{ name: 'product' }" class="nav-link text-dark">
                            Paket Medis</router-link>
                        <router-link :to="{ name: 'post' }" class="nav-link text-dark">
                            Artikel</router-link>
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdUkz-bu7zlpaID2pYxn-AWhhJAq87xh6SdgC2VFDLYTgQkGQ/viewform" 
                            class="nav-link text-dark">Karir</a>
                      
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <h5 class="font-weight-bold">Kontak</h5>
                     <p>
                        <i class="fa fa-phone"></i> +62 822-4949-1929
                    </p>
                    <p>
                        <i class="fa fa-clock"></i> Poliklinik :
                        <br>
                        Senin - Minggu ( 07.00 s/d 20.00 )
                    </p>
                    <p>
                        <i class="fa fa-map-marker"></i> Jl. Raya Legok KM.4 Kampung Bojongnangka Kecamatan Kelapa Dua Kabupaten Tangerang.
                    </p>
                </div>
            </div>
        </div>
          
<!-- Mobile -->
        <div class="d-md-none d-sm-block">
          
            <div class="row">
                <div class="col-md-4 mb-4">
                    <a class="navbar-brand" href="#">
                    <img src="@/assets/logo-rsm.png" alt="" width="150" height="50" />
                    </a>
                    <p>
                        Mendengar, Melayani, Mengasihi.
                    </p>
                    <div class="social">
                        <a href="#" class="mr-4"> <i class="fab fa-facebook fa-2x"></i></a>
                        <a href="#" class="mr-4" style="color:#00c4ff"> <i class="fab fa-twitter fa-2x"></i></a>
                        <a href="#" class="mr-4" style="color: #de2fb8;"> <i class="fab fa-instagram fa-2x"></i></a>
                        <a href="#" style="color: red;"> <i class="fab fa-youtube fa-2x"></i></a>
                    </div>
                </div>
               
                <div class="col-md-4 mb-4">
                    <h5 class="font-weight-bold">Kontak</h5>
                     <p>
                        <i class="fa fa-phone"></i> +62 822-4949-1929
                    </p>
                    <p>
                        <i class="fa fa-clock"></i> Poliklinik :
                        <br>
                        Senin - Minggu ( 07.00 s/d 20.00 )
                    </p>
                    <p>
                        <i class="fa fa-map-marker"></i> Jl. Raya Legok KM.4 Kampung Bojongnangka Kecamatan Kelapa Dua – Tangerang.
                    </p>
                </div>
            </div>
        </div>

            <div class="row text-center mt-3 pb-3">
                <div class="col-md-12">
                    <strong class="text-secondary"> © 2024 PT.Mula Sendang Sejahtera</strong><a href="#" class="text-secondary"> All rights reserved.</a>
                </div>
            </div>

        </div>
    </footer>
</template>

<script>
import { useStore } from "vuex";

    export default {
        name: 'MainFooter',

        setup(){
            const store = useStore();

            return {
            store,
            
            }

        }
        
    }
</script>
