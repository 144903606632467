import { createApp } from 'vue'
import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons';

//import main css
import './assets/css/main.css'

//import Toast
 import Toast from "vue-toastification";
 import "vue-toastification/dist/index.css";
  
 
//import router
import router from './router'


//import mixins
//import mixins from './mixins'

//import store from vuex
import store from './store'


const app = createApp(App)


//gunakan router di vue js dengan plugin "use"
app.use(router)

//gunakan store di vue js dengan plugin "use"
app.use(store)

//gunakan "Toast" di Vue Js dengan plugin "use"
app.use(Toast)

//define mixins for global function

app.mixin({

    methods: {

        //money thousands
        moneyFormat(number) {
            let reverse = number.toString().split('').reverse().join(''),
            thousands   = reverse.match(/\d{1,3}/g)
            thousands   = thousands.join('.').split('').reverse().join('')
            return thousands
        },

        //calculate discount
        calculateDiscount(product) {
            return product.price - (product.price *(product.discount)/100)
        }

    }
})

app.mount('#app')
