//import global API
import Api from '../../api/Api'

const product = {

    //set namespace true
    namespaced: true,

    //state
    state: {
    
        //index products
        products: [],
        promoproducts: [],
        allproducts: [],
        product: {},
        category: {},

    },

    //mutations
    mutations: {

        //set state products dengan data dari response 
        GET_PRODUCTS(state, products) {
            state.products = products
        },

         //set state products dengan data dari response 
         PROMO_PRODUCTS(state, promoproducts) {
            state.promoproducts = promoproducts
        },

        //set state products dengan data dari response 
        ALL_PRODUCTS(state, allproducts) {
            state.allproducts = allproducts
        },

        DETAIL_PRODUCT(state, product) {
            state.product = product
        },

        CATEGORY_PRODUCT(state, category) {
            state.category = category
        },

    },

    //actions
    actions: {

        //action getProducts
        getProducts({ commit }) {

            //get data sliders ke server
            Api.get('/homepage/product')
            .then(response => {

                //commit ke mutation GET_PRODUCTS dengan response data
                commit('GET_PRODUCTS', response.data.data)

            }).catch(error => {

                //show error log dari response
                console.log(error)

            })
        },

        //action allProducts
        allProducts({ commit }) {

            //get data sliders ke server
            Api.get('/products')
            .then(response => {

                //get product MCU
                commit('ALL_PRODUCTS', response.data.products)
                //GET product promo
                commit('PROMO_PRODUCTS', response.data.promo_products)

            }).catch(error => {

                //show error log dari response
                console.log(error)

            })
        },


        getDetailProduct ({ commit }, slug) {

            Api.get(`/product/${slug}`)
            .then(response => {

                commit('DETAIL_PRODUCT', response.data.product)
                commit('CATEGORY_PRODUCT', response.data.product.category)
            }).catch(error => {
                console.log(error)
            })
        },


    },

    //getters
    getters: {

    }

}

export default product
